import React, { useState, useEffect } from "react";
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import Swal from 'sweetalert2';

export default function AddLoacation() {
  if (!document.cookie) {
    localStorage.clear();
    window.location.replace("/");
  }
  const api = "https://test.api.amssolution.in/ams/InsertUpdateLatitudeLongitude";  //live
  //const api = "http://localhost:61251/ams/InsertUpdateLatitudeLongitude";  //test
  const apiget = "https://test.api.amssolution.in/ams/GetCompanyLatLong";  //live
  //const apiget = "http://localhost:61251/ams/GetCompanyLatLong";  //test
  const token = atob(localStorage.getItem('chvshg'));
  const decode = jwt_decode(token);
  const [Id, setId] = useState("0");
  const [latitude, setLatitude] = useState("0");
  const [longitude, setLongitude] = useState("0");

  useEffect(() => {
    axios.get(apiget, { headers: { "Authorization": `Bearer ${token}` } })
      .then(res => {
        res = res.data;
        if (res.responseCode === 200) {
          setId(res.responseResult.Id);
          setLatitude(res.responseResult.Latitude);
          setLongitude(res.responseResult.Longitude);
        }
        else {
          Swal.fire(
            '',
            "No Data Found",
            'error'
          )
        }
      })
      .catch(err => {
        console.log(err);
      })
  }, [])



  const SetLatitudeLongitude = () => {
    navigator.geolocation.getCurrentPosition(function (position) {
      let lat = position.coords.latitude;
      let long = position.coords.longitude;
      console.log(lat, long);
      setLatitude(lat.toFixed(4));
      setLongitude(long.toFixed(4));
    });
  };

  const SubmitLocation = () => {
    if (!latitude) {
      Swal.fire(
        '',
        "Latitude Can Not Be Null..",
        'warning'
      )
      return;
    }
    if (!longitude) {
      Swal.fire(
        '',
        "Longitude Can Not Be Null..",
        'warning'
      )
      return;
    }
    var obj = {
      Id: Id.toString(),
      Latitude: latitude.toString(),
      Longitude: longitude.toString(),
      CreatedBy: decode.EmployeeId.toString(),
      RoleId: decode.RoleId.toString()
    }
    Swal.fire({
      title: 'Are you sure?',
      text: "You Want to save this IP",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post(api, obj, { headers: { "Authorization": `Bearer ${token}` } })
          .then(response => {
            response = response.data;
            if (response.responseCode === 200) {
              Swal.fire(
                'Success',
                response.responseMessage,
                'success'
              )
            }
            else {
              Swal.fire(
                'Failed',
                response.responseMessage,
                'error'
              )
            }
          })
          .catch((error) => {
            console.log(error)
          });
      }
    });
  };

  return (
    <div className="container-fluid pb-3">
      <div className="col-md-12 row">
        <div className="col-md-3"></div>
        <div className="col-md-6">
          <div className="card">
            <div className="card-header">
              <h4>Add Location</h4>
            </div>
            <div className="card-body">
              <div className="form-group row">
                <div className="col-md-5">
                  <label>
                    <b>Latitude</b>
                    <span className="text-danger"> *</span>
                  </label>
                  <input
                    value={latitude}
                    onChange={(e) => {
                      setLatitude(e.target.value);
                    }}
                    type="text"
                    id="txtlatitude"
                    className="form-control"
                  ></input>
                </div>
                <div className="col-md-5">
                  <label>
                    <b>Longitude</b>
                    <span className="text-danger"> *</span>
                  </label>
                  <input
                    type="text"
                    value={longitude}
                    onChange={(e) => {
                      setLongitude(e.target.value);
                    }}
                    className="form-control"
                  ></input>
                </div>
                <div className="col-md-2 text-end">
                  <button
                    onClick={SetLatitudeLongitude}
                    className="btn btn-block btn-secondary mt-4"
                  >
                    Get
                  </button>
                </div>
              </div>
            </div>
            <div className="card-footer text-end">
              <button onClick={SubmitLocation} className="btn btn-success">
                Save
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-3"></div>
      </div>
    </div>
  );
}
