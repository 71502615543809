import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import Oval from 'react-loading-icons/dist/components/oval';

export default function EmployeeDashboard() {
    const token = atob(localStorage.getItem("chvshg"));
    const api_post = "https://test.api.amssolution.in/ams/PunchEmployeeAttendance";
    const [latitude, setLatitude] = useState("0");
    const [longitude, setLongitude] = useState("0");
    const StartLoader = () => {
        document.getElementById("loader").style.display = "inline";
        document.getElementById("btntext").style.display = "none";
        document.getElementById("btn_submit").disabled = true;
    }
    const StopLoader = () => {
        document.getElementById("loader").style.display = "none";
        document.getElementById("btntext").style.display = "inline";
        document.getElementById("btn_submit").disabled = false;
    }
    const SetLatitudeLongitude = () => {
        
    }
    const PunchAttendance = (e) => {
        e.preventDefault();
        StartLoader();
        navigator.permissions.query({ name: 'geolocation' })
        navigator.geolocation.getCurrentPosition(function (position) {
            let lat = position.coords.latitude;
            let long = position.coords.longitude;
            console.log(lat, long);
            setLatitude((pres) => {
                return pres = lat.toFixed(4)
            });
            setLongitude((pres)=>{
               return pres =  long.toFixed(4)
            });
        });
        
        console.log(token);
        let obj = {
            Latitude: latitude.toString(),
            Longitude: longitude.toString()
        }
        console.log(obj);
        axios.post(api_post, obj, { headers: { "Authorization": `Bearer ${token}` } })
            .then(res => {
                StopLoader();
                if (res.data.responseCode === 200) {
                    Swal.fire(
                        'Success.',
                        res.data.responseMessage,
                        'success'
                    )
                }
                else {
                    Swal.fire(
                        'Failed.',
                        res.data.responseMessage,
                        'error'
                    )
                }
            })
            .catch(err => {
                StopLoader();
                console.log(err);
            })
    }
    return (
        <div className="col-md-12">
            <div class="content-center">
                <div className="card">
                    <div className="card-body p-5 content-center">
                        <button id="btn_submit" onClick={PunchAttendance} className="btn btn-success btn-lg"><span style={{ display: "none" }} id="loader"><Oval color="green"></Oval></span> <span id="btntext">Allied</span></button>
                    </div>
                </div>
            </div>
        </div>
    )
}
