import React from 'react'
import { Link, useHistory } from "react-router-dom";
import jwt_decode from "jwt-decode";

export default function Header() {
    const token = atob(localStorage.getItem('chvshg'));
    const decode = jwt_decode(token);
    if (!document.cookie) {
        localStorage.clear();
        window.location.replace("/");
    }
    let history = useHistory();
    const SignOut = () => {
        localStorage.clear();
        history.replace("/");
    }
    return (
        <>
            <header className="p-3 mb-3 border-bottom">
                <div className="container">
                    <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
                        <b><Link to="/dashboard" className="d-flex align-items-center mb-2 mb-lg-0 text-dark text-decoration-none mr-3">
                            AMS
                        </Link></b>
                        {decode.RoleId === "1" ?
                            <ul className="nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0 ml-2">
                                <li><Link to="/addip" className="nav-link px-2 link-dark">IP</Link></li>
                                <li><Link to="/addlocation" className="nav-link px-2 link-dark">Location</Link></li>
                                <li><Link to="/departmentlist" className="nav-link px-2 link-dark">Department</Link></li>
                                <li><Link to="/designationlist" className="nav-link px-2 link-dark">Designation</Link></li>
                                <li><Link to="/employeelist" className="nav-link px-2 link-dark">Employee</Link></li>
                            </ul>
                            : <ul className="nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0 ml-2">
                                <li><Link to="/emp/dashboard" className="nav-link px-2 link-dark">My Profile</Link></li>
                            </ul>
                        }
                        <form className="col-12 col-lg-auto mb-3 mb-lg-0 me-lg-3">
                            <input type="search" className="form-control" placeholder="Search..." aria-label="Search" />
                        </form>

                        <div className="dropdown text-end">
                            <a href="#" className="d-block link-dark text-decoration-none dropdown-toggle" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
                                <img src="https://github.com/mdo.png" alt="mdo" width="32" height="32" className="rounded-circle" />
                            </a>
                            <ul className="dropdown-menu text-small" aria-labelledby="dropdownUser1">
                                <li><Link className="dropdown-item" to="/">New project...</Link></li>
                                <li><Link className="dropdown-item" to="/">Settings</Link></li>
                                <li><Link className="dropdown-item" to="/">Profile</Link></li>
                                <li><hr className="dropdown-divider" /></li>
                                <li><button onClick={SignOut} className="dropdown-item" href="#">Sign out</button></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}
