import React, { useState } from 'react'
import { useHistory } from "react-router-dom";
import axios from "axios";
import md5 from 'md5';
import { Oval } from 'react-loading-icons'
import './Login2.css';
import Swal from 'sweetalert2';
import jwt_decode from 'jwt-decode';
export default function Login2() {
    let history = useHistory();
    const api_live = "https://test.api.amssolution.in/ams/Authentication"; //live
    //const api_live = "http://localhost:61251/ams/Authentication"; //test
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const StartLoading = () => {
        document.getElementById("btn_submit").disabled = true;
        document.getElementById("logo").style.display = "inline";
        document.getElementById("btntext").style.display = "none";
    }
    const StopLoading = () => {
        document.getElementById("btn_submit").disabled = false;
        document.getElementById("logo").style.display = "none";
        document.getElementById("btntext").style.display = "inline";
    }
    const Login = (e) => {
        e.preventDefault();
        if (!email) {
            Swal.fire(
                '',
                "Email Can Not Be Empty..",
                'warning'
            )
            StopLoading();
            return;
        }
        if (!password) {
            Swal.fire(
                '',
                "Password Can Not Be Empty..",
                'warning'
            )
            StopLoading();
            return;
        }
        StartLoading();
        var obj = new Object();
        obj.Username = email;
        obj.Password = md5(password);
        axios.post(api_live, obj)
            .then(response => {
                if (response.data.status === 200) {
                    var now = new Date();
                    var minutes = 30;
                    now.setTime(now.getTime() + (minutes * 60 * 1000));
                    document.cookie = "name=chvshg; expires= " + now.toUTCString() + ";"
                    localStorage.setItem('chvshg', btoa(response.data.token));
                    const dcode = jwt_decode(response.data.token);
                    dcode.RoleId === "1" ? history.push("/dashboard") : history.push("/emp/dashboard");
                }
                else {
                    StopLoading();
                    Swal.fire(
                        '',
                        response.data.message,
                        'error'
                    )
                }
            })
            .catch(error => {
                StopLoading();
                console.log(error);
            })
    }
    return (
        <div className="body">
            <section>
                <main className="form-signin">
                    <form onSubmit={Login}>
                        <div className="img">
                            <span className="ams">AMS</span>
                        </div>
                        <div className="center">
                            <h1 className="h3 mb-3 fw-normal text-muted">Please sign in</h1>
                        </div>
                        <div className="form-floating">
                            <input type="email" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} id="floatingInput" placeholder="name@example.com" />
                            <label htmlFor="floatingInput">Email address</label>
                        </div>
                        <div className="form-floating">
                            <input type="password" className="form-control" value={password} onChange={(e) => setPassword(e.target.value)} id="floatingPassword" placeholder="Password" />
                            <label htmlFor="floatingPassword">Password</label>
                        </div>
                        <div className="checkbox mb-3 center">
                            <label>
                                <input type="checkbox" value="remember-me" /> Remember me
                            </label>
                        </div>
                        <button id="btn_submit" className="w-100 btn btn-lg btn-primary" type="submit"><span id="logo" style={{ display: "none", marginRight: "5px" }} ><Oval height="20px" width="20px" /></span> <span id="btntext">Sign in</span></button>
                        <div className="center">
                            <p className="mt-5 mb-3 text-muted">© 2017–2021</p>
                        </div>
                    </form>
                </main>
            </section>
        </div>
    )
}
