import axios from 'axios';
import React from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
export default function Designation(props) {
    if (!document.cookie) {
        localStorage.clear();
        window.location.replace("/");
    }
    const apipost = "https://test.api.amssolution.in/ams/UpdateCompanyDesignationStatus";
    const token = atob(localStorage.getItem('chvshg'));
    const UpdateStatus = () => {
        var obj = {
            Id: props.Id.toString()
        }
        Swal.fire({
            title: 'Are you sure?',
            text: "You want to update status",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
            axios.post(apipost, obj, { headers: { "Authorization": `Bearer ${token}` } })
                .then(res => {
                    res = res.data;
                    if (res.responseCode === 200) {
                        Swal.fire(
                            'Success!',
                            res.responseMessage.toString(),
                            'success'
                        )
                        props.rerenderParentCallback(true);
                    }
                    else {
                        Swal.fire(
                            'Failed!',
                            res.responseMessage.toString(),
                            'error'
                        )
                    }
                })
                .catch(err => {
                    console.log(err);
                })
            }
        })
    }
    return (
        <tr>
            <td className="text-center">{props.Length + 1}</td>
            <td>{props.Department}</td>
            <td>{props.Designation}</td>
            <td className="text-center"><Link to={`/editdesignation/${props.Id}`} className="text-info btn"><i className="fas fa-edit"></i></Link> / {props.IsActive == true ? <button className="text-danger btn" onClick={UpdateStatus}><i className="fas fa-lock"></i></button> : <button onClick={UpdateStatus} className="text-success btn"><i className="fas fa-unlock"></i></button>}</td>
        </tr>
    )
}
