import React, { useState, useEffect } from 'react'
import jwt_decode from 'jwt-decode';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useParams, Link, useHistory, Redirect } from 'react-router-dom';

export default function EditDeprtment() {
    if (!document.cookie) {
        localStorage.clear();
        window.location.replace("/");
    }
    const history = useHistory();
    const { id } = useParams();
    const [department, setDepartment] = useState("");
    const apipost = "https://test.api.amssolution.in/ams/InsertUpdateDepartment";
    const apiget = "https://test.api.amssolution.in/ams/GetCompanyDepartment";
    const token = atob(localStorage.getItem('chvshg'));
    const decode = jwt_decode(token);

    useEffect(() => {
        var obj = {
            Id: id.toString(),
            IsActive: "0"
        }
        axios.post(apiget, obj, { headers: { "Authorization": `Bearer ${token}` } })
            .then(res => {
                res = res.data;
                if (res.responseCode === 200) {
                    res = res.responseResult;
                    setDepartment(res[0].Department);
                }
                else {
                    alert(res.responseMessage);
                }
            })
            .catch(err => {
                console.log(err);
            })
    }, [])


    const SubmitDepartment = () => {
        if (!department) {
            alert("Department Can Not Be Null");
            return;
        }
        var obj = {
            Id: id.toString(),
            RoleId: decode.RoleId.toString(),
            CompanyId: decode.CompanyId.toString(),
            BranchId: decode.BranchId.toString(),
            Department: department.toString(),
            CreatedBy: decode.EmployeeId.toString()
        }

        Swal.fire({
            title: 'Are you sure?',
            text: "You Want to save this Department",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post(apipost, obj, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(res => {
                        res = res.data;
                        if (res.responseCode === 200) {
                            Swal.fire(
                                'Success!',
                                res.responseMessage.toString(),
                                'success'
                            )
                            history.replace('/departmentlist');
                        }
                        else {
                            Swal.fire(
                                'Failed!',
                                res.responseMessage.toString(),
                                'error'
                            )
                        }
                    })
                    .catch(err => {
                        console.log(err);
                    })
            }
        })
    }
    return (
        <div className="container-fluid pb-3">
            <div className="col-md-12 row">
                <div className="col-md-4"></div>
                <div className="col-md-4">
                    <div className="card">
                        <div className="card-header d-flex justify-content-between">
                            <h4>Add Department</h4>
                            <Link className="btn btn-outline-info" to="/departmentlist">LIST</Link>
                        </div>
                        <div className="card-body">
                            <div className="form-group row">
                                <div className="col-md-12">
                                    <label>
                                        <b>Department</b>
                                        <span className="text-danger"> *</span>
                                    </label>
                                    <input
                                        value={department}
                                        onChange={(e) => {
                                            setDepartment(e.target.value);
                                        }}
                                        type="text"
                                        id="txtdesignation"
                                        className="form-control"
                                    ></input>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer text-end">
                            <button onClick={SubmitDepartment} className="btn btn-success">
                                Save
                            </button>
                        </div>
                    </div>
                </div>
                <div className="col-md-4"></div>
            </div>
        </div>
    )
}
