import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory
} from "react-router-dom";
import Login2 from "./Components/Login2";
import Header from "./Components/Header";
import AddIP from "./Components/AddIP";
import AddLocation from "./Components/AddLocation";
import AddDepartment from "./Components/Department/AddDepartment";
import DepartmentList from "./Components/Department/DepartmentList";
import EditDeprtment from "./Components/Department/EditDeprtment";
import AddDesignation from "./Components/Designation/AddDesignation";
import DesignationList from './Components/Designation/DesignationList';
import EditDesignation from './Components/Designation/EditDesignation';
import AddEmployee from "./Components/Employee/AddEmployee";
import EmployeeList from "./Components/Employee/EmployeeList";
import EditEmployee from './Components/Employee/EditEmployee';
import EmployeeDashboard from './Components/Employee/EmployeeDashboard';

function App() {
  let history = useHistory();
  const [Auth, SetAuth] = useState(false);
  useEffect(() => {
    function fn() {
      if (document.cookie) {
        SetAuth(true);
      } else {
        localStorage.clear();
        SetAuth(false);
        history.replace('/');
      }
    }
  }, [Auth])
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Login2 />
        </Route>
        <Route exact path="/dashboard">
          <Header />
        </Route>
        <Route exact path="/addip">
          <Header /><AddIP />
        </Route>
        <Route exact path="/addlocation">
          <Header /><AddLocation />
        </Route>
        <Route exact path="/designation">
          <Header /><AddDesignation />
        </Route>
        <Route exact path="/department">
          <Header /><AddDepartment />
        </Route>
        <Route exact path="/departmentlist">
          <Header /><DepartmentList />
        </Route>
        <Route exact path="/editdepartment/:id">
          <Header /><EditDeprtment />
        </Route>
        <Route exact path="/designationlist">
          <Header /><DesignationList />
        </Route>
        <Route exact path="/editdesignation/:id">
          <Header /><EditDesignation />
        </Route>
        <Route exact path="/addemployee">
          <Header /><AddEmployee />
        </Route>
        <Route exact path="/employeelist">
          <Header /><EmployeeList />
        </Route>
        <Route exact path="/editemployee/:id">
          <Header /><EditEmployee />
        </Route>
        <Route exact path="/emp/dashboard">
          <Header /><EmployeeDashboard />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
