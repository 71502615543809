import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { useParams, Link, useHistory } from 'react-router-dom';
import DepartmentDDL from '../DropDowns/DepartmentDDL';

export default function EditDesignation() {
    if (!document.cookie) {
        localStorage.clear();
        window.location.replace("/");
    }
    let history = useHistory();
    const { id } = useParams();
    const [designation, setDesignation] = useState("");
    const [departmentId, setDepartmentId] = useState(id);
    const api_post = "https://test.api.amssolution.in/ams/InsertUpdateDesignation";
    const api_get = "https://test.api.amssolution.in/ams/GetCompanyDesignation";
    const token = atob(localStorage.getItem('chvshg'));

    useEffect(() => {
        var obj = {
            Id: id.toString(),
            IsActive: "0",
            DepartmentId: "0"
        }
        axios.get(api_get, obj, { headers: { "Authorization": `Bearer ${token}` } })
            .then(res => {
                res = res.data;
                console.log(res);
                if (res.responseCode === 200) {
                    setDesignation(res.responseResult[0].Designation);
                    setDepartmentId(res.responseResult[0].DepartmentId);
                }
                else {
                    alert(res.responseMessage);
                }
            })
            .catch(err => {
                console.log(err);
            })
    }, [])

    const SubmitDesignation = async () => {
        if (departmentId === "0") {
            alert("Select Department..");
            return;
        }
        if (!designation) {
            alert("Designation Con Not Be Null..");
            return;
        }
        var obj = {
            Id: id.toString(),
            DepartmentId: departmentId.toString(),
            Designation: designation.toString()
        }
        await axios.post(api_post, obj, { headers: { "Authorization": `Bearer ${token}` } })
            .then(res => {
                res = res.data;
                if (res.responseCode === 200) {
                    alert(res.responseMessage);
                    setDesignation("");
                    setDepartmentId(0);
                    history.replace("/designationlist");
                }
                else {
                    alert(res.responseMessage);
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    return (
        <div className="container-fluid pb-3">
        <div className="col-md-12 row">
            <div className="col-md-3"></div>
            <div className="col-md-6">
                <div className="card">
                    <div className="card-header d-flex justify-content-between">
                        <h4>Update Designation</h4>
                        <Link to="/designationlist" className="btn btn-outline-info">LIST</Link>
                    </div>
                    <div className="card-body">
                        <div className="form-group row">
                            <div className="col-md-6">
                                <DepartmentDDL setDepartmentId={setDepartmentId} departmentId={departmentId} />
                            </div>
                            <div className="col-md-6">
                                <label>
                                    <b>Designation</b>
                                    <span className="text-danger"> *</span>
                                </label>
                                <input
                                    value={designation}
                                    onChange={(e) => {
                                        setDesignation(e.target.value);
                                    }}
                                    type="text"
                                    id="txtdesignation"
                                    className="form-control"
                                ></input>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer text-end">
                        <button onClick={SubmitDesignation} className="btn btn-success">
                            Save
                        </button>
                    </div>
                </div>
            </div>
            <div className="col-md-3"></div>
        </div>
        </div>
    )
}
