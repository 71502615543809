import React from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';
import { Link } from 'react-router-dom';
import jwt_decode from 'jwt-decode';

export default function Employee(props) {
    if (!document.cookie) {
        localStorage.clear();
        window.location.replace("/");
    }
    const apipost = "https://test.api.amssolution.in/ams/UpdateCompanyEmployeeStatus";
    const apipost2 = "https://test.api.amssolution.in/ams/VarifyCompanyEmployee";
    const token = atob(localStorage.getItem('chvshg'));
    const UpdateStatus = () => {
        var obj = {
            Id: props.Id.toString()
        }
        Swal.fire({
            title: 'Are you sure?',
            text: "You want to update status",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post(apipost, obj, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(res => {
                        res = res.data;
                        if (res.responseCode === 200) {
                            Swal.fire(
                                'Success!',
                                res.responseMessage.toString(),
                                'success'
                            )
                            props.rerenderParentCallback(true);
                        }
                        else {
                            Swal.fire(
                                'Failed!',
                                res.responseMessage.toString(),
                                'error'
                            )
                        }
                    })
                    .catch(err => {
                        console.log(err);
                    })
            }
        })
    }
    const Approve = (e) => {
        var obj = {
            Id: props.Id.toString()
        }
        Swal.fire({
            title: 'Are you sure?',
            text: "You want to Approve",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post(apipost2, obj, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(res => {
                        res = res.data;
                        if (res.responseCode === 200) {
                            Swal.fire(
                                'Success!',
                                res.responseMessage.toString(),
                                'success'
                            )
                            props.rerenderParentCallback(true);
                        }
                        else {
                            Swal.fire(
                                'Failed!',
                                res.responseMessage.toString(),
                                'error'
                            )
                        }
                    })
                    .catch(err => {
                        console.log(err);
                    })
            }
        })
    }
    return (
        <tr>
            <td className="text-center">{props.Length + 1}</td>
            <td>{props.EmployeeName}</td>
            <td>{props.Department}</td>
            <td>{props.Designation}</td>
            <td>{props.Email}</td>
            <td>{props.Mobile}</td>
            <td className="text-center">
                <Link to={`/editemployee/${props.Id}`} className="text-info btn"><i className="fas fa-edit"></i></Link>
                /
                {props.IsActive === true ?
                    <button className="text-danger btn" onClick={UpdateStatus}><i className="fas fa-lock"></i></button>
                    : <button onClick={UpdateStatus} className="text-success btn"><i className="fas fa-unlock"></i>
                    </button>
                    }
                    {props.IsVerified === null ?
                    <><span>/</span> <button className="text-success btn" onClick={Approve}><i className="fas fa-check"></i></button></>
                    : ""}
            </td>
        </tr>
    )
}
