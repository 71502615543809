import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import DepartmentDDL from '../DropDowns/DepartmentDDL';
import axios from 'axios';
import Swal from 'sweetalert2';
export default function AddDesignation() {
    if (!document.cookie) {
        localStorage.clear();
        window.location.replace("/");
    }
    const [designation, setDesignation] = useState("");
    const [departmentId, setDepartmentId] = useState(0);
    const api_post = "https://test.api.amssolution.in/ams/InsertUpdateDesignation";
    const token = atob(localStorage.getItem('chvshg'));

    const SubmitDesignation = () => {
        if (departmentId === "0") {
            alert("Select Department..");
            return;
        }
        if (!designation) {
            alert("Designation Con Not Be Null..");
            return;
        }
        var obj = {
            Id: "0",
            DepartmentId: departmentId.toString(),
            Designation: designation.toString()
        }
         Swal.fire({
            title: 'Are you sure?',
            text: "You Want to save this Department",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                 axios.post(api_post, obj, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(res => {
                        res = res.data;
                        if (res.responseCode === 200) {
                            Swal.fire(
                                'Success!',
                                res.responseMessage.toString(),
                                'success'
                            )
                            setDesignation("");
                            setDepartmentId(0);
                        }
                        else {
                            Swal.fire(
                                'Failed!',
                                res.responseMessage.toString(),
                                'error'
                            )
                        }
                    })
                    .catch(err => {
                        console.log(err);
                    })
            }
        })
    }

    return (
        <div className="container-fluid pb-3">
        <div className="col-md-12 row">
            <div className="col-md-3"></div>
            <div className="col-md-6">
                <div className="card">
                    <div className="card-header d-flex justify-content-between">
                        <h4>Add Designation</h4>
                        <Link to="/designationlist" className="btn btn-outline-info">LIST</Link>
                    </div>
                    <div className="card-body">
                        <div className="form-group row">
                            <div className="col-md-6">
                                <DepartmentDDL setDepartmentId={setDepartmentId} departmentId={departmentId} />
                            </div>
                            <div className="col-md-6">
                                <label>
                                    <b>Designation</b>
                                    <span className="text-danger"> *</span>
                                </label>
                                <input
                                    value={designation}
                                    onChange={(e) => {
                                        setDesignation(e.target.value);
                                    }}
                                    type="text"
                                    id="txtdesignation"
                                    className="form-control"
                                ></input>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer text-end">
                        <button onClick={SubmitDesignation} className="btn btn-success">
                            Save
                        </button>
                    </div>
                </div>
            </div>
            <div className="col-md-3"></div>
        </div>
        </div>
    )
}
