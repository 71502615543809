import React, { useState, useEffect } from 'react';
import jwt_decode from 'jwt-decode';
import axios from 'axios';

export default function DepartmentDDL(props) {
    const [departmentlist, setDepartmentList] = useState([]);
    const api_get = "https://test.api.amssolution.in/ams/GetCompanyDepartment";
    const token = atob(localStorage.getItem('chvshg'));
    const decode = jwt_decode(token);
    useEffect(async () => {
        var obj = {
            Id: "0",
            IsActive: "1"
        }
        await axios.get(api_get, obj, { headers: { "Authorization": `Bearer ${token}` } })
            .then(res => {
                res = res.data;
                if (res.responseCode === 200) {
                    setDepartmentList(res.responseResult);
                }
                else {
                    alert(res.responseMessage);
                }
            })
            .catch(err => {
                console.log(err);
            })
    }, [])
    return (
        <>
            <label>
                <b>Departmant</b>
                <span className="text-danger"> *</span>
            </label>
            <select className="form-select" value={props.departmentId} onChange={(e)=>{props.setDepartmentId(e.target.value)}}  aria-label="Default select example">
                <option value={0}>--select--</option>
                {departmentlist.map((dep) => {
                    // return props.departmentId === dep.Id ? <option key={dep.Id} value={dep.Id} selected>{dep.Department}</option> : <option key={dep.Id} value={dep.Id}>{dep.Department}</option>;
                    return  <option key={dep.Id} value={dep.Id} selected>{dep.Department}</option>;
                })}
            </select>
        </>
    )
}
