import React, { useState, useEffect } from 'react';
import DepartmentDDL from '../DropDowns/DepartmentDDL';
import DesignationDDL from '../DropDowns/DesignationDDL';
import Swal from 'sweetalert2';
import axios from 'axios';
import { useParams, Link, useHistory, Redirect } from 'react-router-dom';

export default function EditEmployee() {
    if (!document.cookie) {
        localStorage.clear();
        window.location.replace("/");
    }
    const { id } = useParams();
    const history = useHistory();
    const [employeename, setEmployeeName] = useState("");
    const [departmentId, setDepartmentId] = useState(-1);
    const [designationId, setDesignationId] = useState("0");
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    const [address, setAddress] = useState("");
    const api_post = "https://test.api.amssolution.in/ams/InsertUpdateMasterCompanyEmployee";
    const api_get = "https://test.api.amssolution.in/ams/GetCompanyEmployeeDetails";
    const token = atob(localStorage.getItem("chvshg"));

    useEffect(() => {
        var obj = {
            Id: id.toString(),
            IsActive: "0"
        }
        axios.get(api_get, obj, { headers: { "Authorization": `Bearer ${token}` } })
            .then(res => {
                res = res.data;
                if (res.responseCode === 200) {
                    res = res.responseResult;
                    setEmployeeName(res[0].EmployeeName)
                    setDepartmentId(res[0].DepartmentId);
                    setDesignationId(res[0].DesignationId);
                    setEmail(res[0].Email);
                    setMobile(res[0].Mobile);
                    setAddress(res[0].Address);
                }
                else {
                    Swal.fire(
                        'Failed!',
                        res.responseMessage.toString(),
                        'error'
                    )
                }
            })
            .catch(err => {
                console.log(err);
            })
    }, [])

    const submit = (e) => {
        e.preventDefault();
        if (employeename === "") {
            Swal.fire(
                '',
                "Employee Name Can Not Be Null..",
                'warning'
            )
            return;
        }
        if (departmentId === "0" || departmentId === -1) {
            Swal.fire(
                '',
                "Select Department..",
                'warning'
            )
            return;
        }
        if (designationId === "0") {
            Swal.fire(
                '',
                "Select Designation..",
                'warning'
            )
            return;
        }
        if (email === "") {
            Swal.fire(
                '',
                "Email Con Not Be Null..",
                'warning'
            )
            return;
        }
        if (mobile === "") {
            Swal.fire(
                '',
                "Mobile Con Not Be Null..",
                'warning'
            )
            return;
        }
        if (address === "") {
            Swal.fire(
                '',
                "Address Con Not Be Null..",
                'warning'
            )
            return;
        }
        var obj = {
            Id: id.toString(),
            EmployeeName: employeename,
            DepartmentId: departmentId.toString(),
            DesignationId: designationId.toString(),
            Mobile: mobile,
            Email: email,
            Addess: address
        }
        Swal.fire({
            title: 'Are you sure?',
            text: "You Want to update this Department",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post(api_post, obj, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(res => {
                        res = res.data;
                        if (res.responseCode === 200) {
                            Swal.fire(
                                'Success!',
                                res.responseMessage.toString(),
                                'success'
                            )
                            history.replace('/employeelist');
                        }
                        else {
                            Swal.fire(
                                'Failed!',
                                res.responseMessage.toString(),
                                'error'
                            )
                        }
                    })
                    .catch(err => {
                        console.log(err);
                    })
            }
        })
    }

    return (
        <div className="container-fluid pb-3">
            <div className="col-md-12 row">
                <div className="col-md-2"></div>
                <div className="col-md-8">
                    <div className="card">
                        <div className="card-header d-flex justify-content-between">
                            <h4>Add Employee</h4>
                            <Link className="btn btn-outline-info" to="/employeelist">LIST</Link>
                        </div>
                        <div className="card-body">
                            <div className="form-group row py-1">
                                <div className="col-md-4">
                                    <label>
                                        <b>Employee Name</b>
                                        <span className="text-danger"> *</span>
                                    </label>
                                    <input
                                        type="text"
                                        value={employeename}
                                        onChange={(e) => {
                                            setEmployeeName(e.target.value);
                                        }}
                                        className="form-control"
                                    ></input>
                                </div>
                                <div className="col-md-4">
                                    <DepartmentDDL setDepartmentId={setDepartmentId} departmentId={departmentId} />
                                </div>
                                <div className="col-md-4">
                                    <DesignationDDL setDesignationId={setDesignationId} departmentId={departmentId} designationId={designationId} />
                                </div>
                            </div>
                            <div className="form-group row py-1">
                                <div className="col-md-4">
                                    <label>
                                        <b>Email</b>
                                        <span className="text-danger"> *</span>
                                    </label>
                                    <input
                                        value={email}
                                        onChange={(e) => {
                                            setEmail(e.target.value);
                                        }}
                                        type="text"
                                        className="form-control"
                                    ></input>
                                </div>
                                <div className="col-md-4">
                                    <label>
                                        <b>Mobile</b>
                                        <span className="text-danger"> *</span>
                                    </label>
                                    <input
                                        type="text"
                                        value={mobile}
                                        onChange={(e) => {
                                            setMobile(e.target.value);
                                        }}
                                        className="form-control"
                                    ></input>
                                </div>
                                <div className="col-md-4">
                                    <label>
                                        <b>Address</b>
                                        <span className="text-danger"> *</span>
                                    </label>
                                    <input
                                        value={address}
                                        onChange={(e) => {
                                            setAddress(e.target.value);
                                        }}
                                        type="text"
                                        className="form-control"
                                    ></input>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer text-end">
                            <button onClick={submit} className="btn btn-success">Save</button>
                        </div>
                    </div>
                </div>
                <div className="col-md-2"></div>
            </div>
        </div>
    );
}
