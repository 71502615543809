import React,{useState,useEffect} from 'react';
import axios from 'axios';
export default function DesignationDDL(props) {
    const [designationlist, setDesignationList] = useState([]);
    const api_get = "https://test.api.amssolution.in/ams/GetCompanyDesignation";
    const token = atob(localStorage.getItem('chvshg'));

    useEffect(async () => {
        var obj = {
            Id: "0",
            IsActive: "1",
            DepartmentId: props.departmentId.toString() == "0" ? "-1" : props.departmentId.toString()
        }
        await axios.get(api_get, obj, { headers: { "Authorization": `Bearer ${token}` } })
            .then(res => {
                res = res.data;
                if (res.responseCode === 200) {
                    setDesignationList(res.responseResult);
                }
                else {
                    alert(res.responseMessage);
                }
            })
            .catch(err => {
                console.log(err);
            })
    }, [props.departmentId])
    return (
        <>
            <label>
                <b>Designation</b>
                <span className="text-danger"> *</span>
            </label>
            <select className="form-select" value={props.designationId} onChange={(e)=>{props.setDesignationId(e.target.value)}}  aria-label="Default select example">
                <option value={"0"}>--select--</option>
                {designationlist.map((des) => {
                    return <option key={des.Id} value={des.Id}>{des.Designation}</option>;
                })}
            </select>
        </>
    )
}
