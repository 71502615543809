import React, { useState, useEffect } from "react";
import axios from "axios";
import jwt_decode from "jwt-decode";
import Oval from "react-loading-icons/dist/components/oval";
import Swal from "sweetalert2";
export default function AddIP() {
  if (!document.cookie) {
    localStorage.clear();
    window.location.replace("/");
  }
  const [IpId, setIpId] = useState("0");
  const [ip, setIp] = useState("0");
  const apipost = "https://test.api.amssolution.in/ams/InsertUpdateIPDetails"; //live
  //const apipost = "http://localhost:61251/ams/InsertUpdateIPDetails"; //test
  const apiget_live = "https://test.api.amssolution.in/ams/GetIPDetails"; //live
  //const apiget_live = "http://localhost:61251/ams/GetIPDetails"; //test
  const token = atob(localStorage.getItem('chvshg'));
  var decoded = jwt_decode(token);

  const StartLoading = () => {
    document.getElementById("btn_submit").disabled = true;
    document.getElementById("loader").style.display = "inline";
  }
  const StopLoading = () => {
    document.getElementById("btn_submit").disabled = false;
    document.getElementById("loader").style.display = "none";
  }
  useEffect(() => {
    try {
      axios.get(apiget_live, { headers: { "Authorization": `Bearer ${token}` } })
        .then(resp => {
          let result = resp.data;
          if (result.responseCode === 200) {
            setIp(result.responseResult.IP);
            setIpId(result.responseResult.Id);
          }
        })
        .catch(err => {
          console.log(err)
        })
    }
    catch {
      console.log("INTERNAL SERVER ERROR");
    }
  }, [])

  const getIP = () => {
    StartLoading();
    axios.get("https://test.api.amssolution.in/ams/GetYourIp")
      .then(response => {
        if (response.data.responseCode === 200) {
          setIp(response.data.ip);
          StopLoading();
        }
        else {

        }
      })
      .catch(error => {
        console.log(error);
        StopLoading();
      })
  }

  const SubmitIp = () => {
    if (!ip) {
      Swal.fire(
        '',
        "Latitude Can Not Be Null..",
        'warning'
      )
      return;
    }
    let obj = { IP: ip.toString(), Id: IpId.toString() };
    Swal.fire({
      title: 'Are you sure?',
      text: "You Want to save this IP",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post(apipost, obj, { headers: { "Authorization": `Bearer ${token}` } })
          .then(res => {
            if (res.data.responseCode === 200) {

              Swal.fire(
                'Success!',
                res.data.responseMessage,
                'success'
              )
            }
            else {
              Swal.fire(
                'Failed!',
                res.data.responseMessage,
                'error'
              )
            }
          })
          .catch((error) => {
            console.log(error)
          });
      }
    })
  };

  return (
    <div className="container-fluid pb-3">
      <div className="col-md-12 row">
        <div className="col-md-3"></div>
        <div className="col-md-6">
          <div className="card">
            <div className="card-header">
              <h4>Add IP</h4>
            </div>
            <div className="card-body">
              <div className="form-group row">
                <div className="col-md-9">
                  <label>
                    <b>IP</b>
                    <span className="text-danger"> *</span>
                  </label>
                  <input
                    value={ip}
                    onChange={(e) => {
                      setIp(e.target.value);
                    }}
                    type="text"
                    id="txtlatitude"
                    className="form-control"
                  ></input>
                </div>
                <div className="col-md-3 text-end">
                  <button
                    id="btn_submit"
                    onClick={getIP}
                    className="btn btn-block btn-secondary mt-4"
                  >
                    <span id="loader" style={{ display: "none", marginRight: "5px" }} ><Oval height="20px" width="20px" /></span> Get IP
                  </button>
                </div>
              </div>
            </div>
            <div className="card-footer text-end">
              <button onClick={SubmitIp} className="btn btn-success">
                Save
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-3"></div>
      </div>
    </div>
  );
}
