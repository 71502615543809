import { Link } from 'react-router-dom';
import Department from './Department';
import axios from 'axios';
import React, { useState, useEffect } from 'react';


export default function DepartmentList() {
    if (!document.cookie) {
        localStorage.clear();
        window.location.replace("/");
    }
    const [departmentlist, setDepartmentList] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const apipost = "https://test.api.amssolution.in/ams/GetCompanyDepartment";  //live
    //const apipost = "http://localhost:61251/ams/GetCompanyDepartment";  //test
    const token = atob(localStorage.getItem('chvshg'));
    useEffect(() => {
        var obj = {
            Id: "0",
            IsActive: "0"
        }
        axios.post(apipost, obj, { headers: { "Authorization": `Bearer ${token}` } })
            .then(res => {
                res = res.data;
                if (res.responseCode === 200) {
                    setDepartmentList(res.responseResult);
                }
                else {
                    alert(res.responseMessage);
                }
            })
            .catch(err => {
                console.log(err);
            })
        setRefresh(false);
    }, [refresh])

    return (
        <div className="container-fluid pb-3">
        <div className="col-md-12 row">
            <div className="col-md-2"></div>
            <div className="col-md-8">
                <div className="card">
                    <div className="card-header d-flex justify-content-between">
                        <h3>Manage Departments</h3>
                        <Link className="btn btn-outline-info" to="/department">ADD</Link>
                    </div>
                    <div className="card-body">
                        <table className="table table-bordered">
                            <thead className="thead-dark">
                                <tr>
                                    <th className="text-center">#</th>
                                    <th scope="col">Department Name</th>
                                    <th className="text-center" scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {departmentlist.map((department) => {
                                    return <Department key={department.Id} Id={department.Id} rerenderParentCallback={setRefresh} Department={department.Department} IsActive={department.IsActive} Length={departmentlist.indexOf(department)} />
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="col-md-2"></div>
        </div >
        </div>
    )
}
