import React, { useEffect, useState } from 'react';
import axios from "axios";
import Swal from 'sweetalert2';
import Employee from './Employee';
import { Link } from 'react-router-dom';

export default function EmployeeList() {
    if (!document.cookie) {
        localStorage.clear();
        window.location.replace("/");
    }
    const [employeelist, setEmployeeList] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const api_get = "https://test.api.amssolution.in/ams/GetCompanyEmployeeDetails";
    const token = atob(localStorage.getItem('chvshg'));

    useEffect(async () => {
        var obj = {
            Id: "0",
            IsActive: "0"
        }
        await axios.post(api_get, obj, { headers: { "Authorization": `Bearer ${token}` } })
            .then(res => {
                res = res.data;
                if (res.responseCode === 200) {
                    setEmployeeList(res.responseResult);
                }
                else {
                    Swal.fire(
                        'Error',
                        res.responseMessage,
                        'error'
                    )
                }
            })
            .catch(err => {
                console.log(err);
            })
        setRefresh(false);
    }, [refresh])
    return (
        <div className="container-fluid pb-3">
            <div className="col-md-12 row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header d-flex justify-content-between">
                            <h3>Manage Employee</h3>
                            <Link className="btn btn-outline-info" to="/addemployee">ADD</Link>
                        </div>
                        <div className="card-body">
                            <div style={{overflowX:"auto"}}>
                                <table className="table table-bordered table-responsive">
                                    <thead className="thead-dark">
                                        <tr>
                                            <th className="text-center">#</th>
                                            <th scope="col">Employee Name</th>
                                            <th scope="col">Department</th>
                                            <th scope="col">Designation</th>
                                            <th scope="col">Email</th>
                                            <th scope="col">Mobile</th>
                                            <th className="text-center" scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {employeelist.map((employee) => {
                                            return <Employee key={employee.Id} IsVerified={employee.IsVerified} Id={employee.Id} rerenderParentCallback={setRefresh} EmployeeName={employee.EmployeeName} Designation={employee.Designation} Department={employee.Department} IsActive={employee.IsActive} Email={employee.Email} Mobile={employee.Mobile} Length={employeelist.indexOf(employee)} />
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div>
    )
}
